<template>
  <top :title="'软件平台智慧服装系统'"></top>
  <contents></contents>
  <digitizings></digitizings>
  <common-footer />
</template>
<script>
// import axios from "axios";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
import top from "@components/top.vue";
import contents from "@/views/software/components/content.vue";
import digitizings from "@/views/software/components/digitizings.vue";
export default {
  components: {
    CommonFooter,
    top,
    digitizings,
    contents,
  },
  computed: {},
  methods: {
    ...mapMutations([
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
  },

  mounted() {
    this.setheaderTopBtnWhite(false);
  },
  unmounted() {
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped></style>
