<template>
  <div class="content">
    <div class="systemIntroduction">
      <Title :title="'鞋服行业RFID整体解决方案系统介绍'" class="titles"></Title>
      <div class="subTitle wow fadeInUp">
        {{
          "“鞋服行业RFID整体解决方案系统”利用先进RFID数据载体将服装产品从订货管理、生产管理、仓储管理、门店管理、防伪防窜管理等连接到一起，提升供应链各环节的全面保障，实现产品的数据化、可视化的高效运营模式。"
        }}
      </div>
    </div>
    <div class="nav-warp">
      <div class="nav">
        <div
          class="nav-item"
          v-for="(item, index) in systemData"
          :key="index"
          @click="clickNav(index)"
          :class="{ selectedColor: current == index }"
        >
          <span class="pc">{{ item.name }}</span>
          <span class="moblie">{{ item.mobileName }}</span>
          <div :class="{ selecteds: current == index }"></div>
        </div>
      </div>
    </div>
    <div class="contents-warp wow fadeInUp" ref="contentsWarp">
      <div class="systemContent">
        <img class="pc"
          :src="require('@/assets/software/' + systemData[current]['img'])"
        />
        <img class="moblie"
             :src="require('@/assets/software/' + systemData[current]['imgM'])"
        />
      </div>
      <div class="phoneMes" v-if="Object.keys(phoneData[current]).length">
        <div class="phoneTitle">
          <span class="titles">{{ phoneData[current].name }}</span>
          <span class="titlesColor">{{ phoneData[current].suffix }}</span>
        </div>
        <div class="phoneContent">
          <div class="phoneLeft">
            <div class="meritTitle">
              {{ phoneData[current].meritTitle }}
            </div>
            <div
              class="meritItem"
              v-for="(item, index) in phoneData[current]['merit']"
              :key="index"
            >
              <img :src="require('@/assets/gou.png')" />
              <div>{{ item }}</div>
            </div>
          </div>
          <div class="phoneRight">
            <div
              class="phoneItem"
              v-for="(item, index) in phoneData[current]['phone']"
              :key="index"
            >
              <img :src="require('@/assets/software/' + item.img)" />
              <div class="phoneTips">{{ item.tips }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <videoShow :types="videoData[current]"></videoShow>
  </div>
</template>
<script setup>
const systemData = [
  {
    name: "RFID自动接单",
    mobileName: "自动接单",
    img: "Receiving.png",
    imgM: "Receiving-m.png",
  },
  {
    name: "自动排版AI出稿",
    mobileName: "自动排版",
    img: "setType.png",
    imgM: "setType-m.png",
  },
  {
    name: "RFID鞋服工厂数据中心",
    mobileName: "数据中心",
    img: "factory.png",
    imgM: "factory-m.png",
  },
  {
    name: "RFID鞋服仓储系统",
    mobileName: "鞋服仓储",
    img: "keepGrain.png",
    imgM: "keepGrain-m.png",
  },
  {
    name: "RFID鞋服门店系统",
    mobileName: "鞋服门店",
    img: "store.png",
    imgM: "store-m.png",
  },
];
const phoneData = [
  {},
  {},
  {},
  {
    name: "RFID鞋服仓储系统",
    suffix: "PDA",
    meritTitle: "主要流程",
    merit: [
      "通过PDA快速记录物品的基本信息。",
      "通过PDA查询库存信息，方便出库操作并自动记录操作信息。",
      "通过PDA快速采集库存数据，判断库存缺失或盈余并调整库存。",
    ],
    phone: [
      {
        tips: "登录界面",
        img: "3-2-1.png",
      },
      {
        tips: "入库",
        img: "3-2-2.png",
      },
      {
        tips: "盘点",
        img: "3-2-3.png",
      },
      {
        tips: "货物详情",
        img: "3-2-4.png",
      },
      {
        tips: "装车配送",
        img: "3-2-5.png",
      },
    ],
  },
  {
    name: "RFID鞋服门店系统",
    suffix: "PDA",
    meritTitle: "门店收/发货流程",
    merit: [
      "支持按单/按箱复核",
      "支持无网或弱网环境下都能对RFID产品的感应采集",
      "支持数据本地缓存，有网环境数据提交",
    ],
    phone: [
      {
        tips: "登录界面",
        img: "3-1-1.png",
      },
      {
        tips: "采购入店",
        img: "3-1-2.png",
      },
      {
        tips: "商品盘点",
        img: "3-1-3.png",
      },
      {
        tips: "快速找货",
        img: "3-1-4.png",
      },
      {
        tips: "商品销售",
        img: "3-1-5.png",
      },
    ],
  },
];
const videoData = ["", "setType", "factory", "store", "shop"];
</script>
<script>
import Title from "@/views/plans/components/Title.vue";
import videoShow from "@/views/software/components/videoShow.vue";
import $ from "jquery";
export default {
  data() {
    return {
      current: 0,
    };
  },
  components: {
    Title,
    videoShow,
  },
  methods: {
    clickNav(index) {
      this.current = index;
      $(window).scrollTop(this.$refs.contentsWarp.offsetTop - 60);
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  img {
    width: 100%;
  }
  ::v-deep(.titles) {
    .plansTitle {
      padding-top: 0.8rem;
    }
  }
  .subTitle {
    padding: 0 3rem;
    text-align: center;
    font-size: 0.18rem;
    font-weight: normal;
    color: #7a7a7a;
    line-height: 38px;
    margin-bottom: 0.4rem;
    @if ($searchWidth<1100) {
      font-size: 0.7rem;
      line-height: 1rem;
      padding: 0 0.8rem;
      margin-bottom: 1rem;
    } @else {
      max-width: 10rem;
    }
  }
  .content {
    .nav-warp {
      background: #ffffff;
      position: sticky;
      top: 0;
      height: fit-content;
      @if ($searchWidth<1100) {
        width: 100%;
      }
      .nav {
        width: 100%;
        height: 0.82rem;
        flex-shrink: 0;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: relative;
        @if ($searchWidth<1100) {
          height: 3.2rem;
          justify-content: space-around;
        }

        .nav-item {
          display: flex;
          flex-direction: column;
          font-size: 0.22rem;
          width: 2.4rem;
          justify-content: center;
          align-items: center;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #b6c1c0;
          box-sizing: border-box;
          position: relative;
          @if ($searchWidth<1100) {
            font-size: 0.7rem;
            width: 20%;
            white-space: nowrap;
          }
          .selecteds {
            position: absolute;
            bottom: 0;
            background: #5ec7b9;
            width: 0.9rem;
            height: 4px;
            @if ($searchWidth<1100) {
              width: 1.6rem;
              height: 2px;
            }
          }
        }
        .selectedColor {
          color: #333333;
        }
      }
    }
    .systemIntroduction {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    }
    .contents-warp {
      background: #f7f8fa;
      padding-top: 0.4rem;
      @if ($searchWidth<1100) {
        padding-top: 20px;
      }
    }
    .systemContent {
      width: 80%;
      max-width: 1340px;
      margin: 0 auto;
      @if ($searchWidth<1100) {
        width: calc(100% - 20px);
        margin: 0 auto;
        height: auto;
      }
      img {
        height: 100%;
      }
    }
    .phoneMes {
      padding-bottom: 0.5rem;
      background: #f7f8fa;
      .phoneTitle {
        padding: 40px 0 50px;
        text-align: center;
        font-size: 0.44rem;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 60px;
        letter-spacing: 2px;
        @if ($searchWidth<1100) {
          font-size: 1.4rem;
          line-height: 2rem;
          padding: 10px 0 20px;
        }
        .titlesColor {
          color: #5ec7b9;
          margin-left: 16px;
        }
      }
      .phoneContent {
        width: 80%;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        @if ($searchWidth<1100) {
          flex-direction: column;
          width: 100%;
        }
        .phoneLeft {
          @if ($searchWidth<1100) {
            margin: 0 20px;
          }
          .meritTitle {
            font-size: 0.24rem;
            font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #333333;
            line-height: 46px;
            @if ($searchWidth<1100) {
              font-size: 1rem;
              margin: 0 20px;
            }
          }
          .meritItem {
            display: flex;
            align-items: center;
            @if ($searchWidth<1100) {
              align-items: flex-start;
            }
            img {
              width: 0.2rem;
              height: 0.2rem;
              @if ($searchWidth<1100) {
                width: 1.2rem;
                height: 1.2rem;
                transform: translateY(50%);
              }
            }
            div {
              margin-left: 5px;
              font-size: 0.16rem;
              font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #1d403b;
              line-height: 46px;
              @if ($searchWidth<1100) {
                font-size: 0.8rem;
              }
            }
          }
        }
        .phoneRight {
          margin-left: 0.4rem;
          display: flex;
          @if ($searchWidth<1100) {
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: 0 0 20px;
          }
          .phoneItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 0.05rem;
            @if ($searchWidth<1100) {
              width: 50%;
              margin: 0;
            }
            img {
              @if ($searchWidth<1100) {
                width: 100%;
              } @else {
                height: 2.6rem;
              }
            }
            .phoneTips {
              width: 1.41rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.16rem;
              font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #333333;
              line-height: 22px;
              background: #ffffff;
              box-shadow: 0px 2px 10px 9px #f0f5f4;
              padding: 5px 0;
              @if ($searchWidth<1100) {
                width: 90%;
                font-size: 0.8rem;
              }
            }
          }
          :last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
