<template>
  <div class="videoShow" v-if="videoShowData">
    <plansTitle :title="videoShowData.title"></plansTitle>
    <div class="content">
      <div class="left">
        <div class="subTitle">
          {{ videoShowData.subTitle }}
        </div>
        <div class="mes">
          {{ videoShowData.mes }}
        </div>
        <div class="meritWarp">
          <div
            class="meritItem"
            v-for="(item, index) in videoShowData.merit"
            :key="index"
            :class="{ oneRow: videoShowData.isOne }"
          >
            <img :src="require('@/assets/gou.png')" />
            <div class="merits">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <video
          :src="require('@/assets/software/video/' + videoShowData.video)"
          controls="controls"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
const videoShowData = [
  {
    title: "现场视频演示",
    subTitle: "AI自动排版出稿系统的价值：",
    mes: "",
    types: "setType",
    isOne: true,
    merit: [
      "提高生产效率",
      "减少出错率",
      "实现定制化",
      "使用PLC控制生产过程",
      "优化供应链管理",
      "减少排版费用",
    ],
    video: "setType.mp4",
  },
  {
    title: "现场视频演示",
    subTitle: "利用RFID工厂数据中心进行生产管理的简要流程：",
    mes: "",
    types: "factory",
    isOne: true,
    merit: [
      "准备RFID标签和读写器",
      "编码标签，将生产物品用RFID标签标识",
      "使用RFID读写器读取标签中的信息",
      "使用PLC控制生产过程",
      "数据传输到RFID工厂数据中心进行存储和解析",
      "利用RFID数据中心的分析工具对生产数据进行分析",
    ],
    video: "factory.mp4",
  },
  {
    title: "现场视频演示",
    subTitle: "用手持设备进行仓储盘点的流程",
    mes: "利用RFID技术进行盘点时，可以使用RFID标签自动扫描替代手动扫描货架和商品条码，简化流程并提高盘点效率。",
    types: "store",
    merit: [
      "准备手持设备和盘点软件",
      "设置手持设备连接计算机或仓库管理系统",
      "设定盘点计划并确定盘点区域",
      "扫描货架或区域的条码信息，并将其录入计算机或其他资产管理系统",
      "逐一扫描库存货物的条码信息，记录库存数据",
      "将采集到的库存数据传输到资产管理系统，进行数据校对和上传",
      "生成盘点报告并分析盘点结果，提供改进措施",
    ],
    video: "storehouse.mp4",
  },
  {
    title: "现场视频演示",
    subTitle: "用手持设备进行服装盘点的流程",
    mes: "利用RFID技术进行盘点时，可以自动扫描RFID标签，简化了数据采集和条码扫描的工作。",
    types: "shop",
    merit: [
      "获取手持设备和盘点软件",
      "盘点数据采集",
      "设备设置和连接",
      "数据校验和上传",
      "建立盘点计划",
      "生成报告和分析",
      "扫描服装标签",
    ],
    video: "shop.mp4",
  },
];
import plansTitle from "@/views/plans/components/Title.vue";

export default {
  data() {
    return {};
  },
  components: {
    plansTitle,
  },
  props: {
    types: {
      type: String,
      default: "",
    },
  },
  created() {
    this.videoShowData = videoShowData.find((item) => item.types == this.types);
  },
  beforeUpdate() {
    this.videoShowData = videoShowData.find((item) => item.types == this.types);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  img {
    width: 100%;
  }
  .videoShow {
    width: 100%;
    background-color: #f7f8fa;
    padding-bottom: 0.5rem;
  }

  .content {
    display: flex;
    width: 12rem;
    margin: 0 auto;
    padding: 0.5rem;
    background: linear-gradient(179deg, #fafcff 0%, #ffffff 100%);
    box-shadow: -2px 6px 12px 5px rgba(109, 154, 148, 0.18),
      inset 0px 1px 3px 0px rgba(255, 255, 255, 0.5);
    border: 5px solid #ffffff;
    @if ($searchWidth<1100) {
      width: 100%;
      flex-direction: column;
      padding: 15px;
      box-sizing: border-box;
    }
  }
  .left {
    flex: 1;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    .subTitle {
      font-size: 24px;
      font-weight: normal;
      color: #333333;
      line-height: 46px;
    }
    .mes {
      font-size: 18px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1d403b;
      line-height: 24px;
      margin: 0.15rem 0 0.2rem;
      @if ($searchWidth<1100) {
        margin: 10px 0 20px;
      }
    }
    .meritWarp {
      display: flex;
      flex-wrap: wrap;
      .meritItem {
        min-width: 50%;
        display: flex;
        margin-bottom: 15px;
        img {
          position: relative;
          top: 4px;
          width: 18px;
          height: 18px;
        }
        .merits {
          margin-left: 10px;
          font-size: 16px;
          font-weight: normal;
          color: #7a7a7a;
          line-height: 25px;
        }
      }
      .oneRow {
        min-width: 60%;
      }
    }
  }
  .right {
    margin-left: 0.5rem;
    width: 6.4rem;
    @if ($searchWidth<1100) {
      margin: 0;
      width: 100%;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
