<template>
  <div class="box">
    <div class="f1">系统数字化建设</div>
    <div class="list">
      <div class="item" v-for="(item, index) in buildData" :key="index">
        <div>
          <div class="name">
            <div class="name-zh">
              <div class="name-icon">
                <img :src="require('@/assets/software/' + item.icon)" />
              </div>
              <div class="zh">{{ item.cnName }}</div>
            </div>
            <div class="en">{{ item.enName }}</div>
          </div>
          <div class="icon">
            <img :src="require('@/assets/software/' + item.logo)" />
          </div>
        </div>
        <div class="s">
          <div
            class="item-s"
            v-for="(sItem, sIndex) in item.merit"
            :key="sIndex"
          >
            {{ sItem }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buildData: [
        {
          cnName: "库存盘点",
          enName: "Inventory counting",
          icon: "1-1.png",
          logo: "1-1-1.png",
          merit: [
            "库存货物快速盘点",
            "库存可视化管理",
            "作业效率高",
            "供应链反馈速度快",
          ],
        },
        {
          cnName: "智能导购",
          enName: "Intelligent guide",
          icon: "1-2.png",
          logo: "1-2-1.png",
          merit: [
            "自动识别标签连接数据库",
            "实时获取商品信息",
            "快速响应客户需求",
          ],
        },
        {
          cnName: "销售管理",
          enName: "Sales management",
          icon: "1-3.png",
          logo: "1-3-1.png",
          merit: [
            "自动统计商品销售情况",
            "实时上传数据库系统",
            "及时指定经营策略",
          ],
        },
        {
          cnName: "安全管理",
          enName: "Security management",
          icon: "1-4.png",
          logo: "1-4-1.png",
          merit: ["全球唯一的ID号码", "自动批量盘点", "蚂蚁金服区跨链溯源"],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .box {
    background: #ffffff;
    border-bottom: 1px solid #E5E6EB;
    @if ($searchWidth<1100) {
      padding-bottom: 3rem;
    } @else {
      padding-bottom: 0.6rem;
    }
  }

  .list {
    display: grid;
    grid-column-gap: 0.4rem;

    @if ($searchWidth<1100) {
      padding: 0 0.8rem;
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 1rem;
      margin: 1rem auto 0;
    } @else {
      max-width: 13.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 0.4rem;
      margin: 0.5rem auto 0;
    }
  }

  .item {
    background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    box-shadow: 0 0.02rem 0.11rem 0.04rem rgba(207, 207, 207, 0.2),
      inset 0 0.02rem 0.03rem 0 #ffffff;

    border: 0.07rem solid #ffffff;
    box-sizing: border-box;

    @if ($searchWidth<1100) {
      border-radius: 0.5rem;
      padding: 0.65rem 0.5rem 1rem;
    } @else {
      border-radius: 0.1rem;
      padding: 0.02rem 0.2rem 0.4rem;
    }

    > div {
      display: flex;
      align-items: flex-start;

      &:first-child {
        justify-content: space-between;
      }

      &:nth-child(2) {
      }
    }

    .name {
      img {
        display: block;

        @if ($searchWidth<1100) {
          width: 1rem;
          margin-right: 0.4rem;
        } @else {
          width: 0.4rem;
          margin-right: 0.22rem;
        }
      }

      .name-zh {
        display: flex;
        margin-bottom: 0.04rem;
        margin-top: 0.16rem;
      }

      .zh {
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        letter-spacing: 0.01rem;

        @if ($searchWidth<1100) {
          font-size: 1rem;
          line-height: 1;
        } @else {
          font-size: 0.3rem;
          line-height: 0.42rem;
        }
      }

      .en {
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #7a7a7a;

        @if ($searchWidth<1100) {
          font-size: 0.7rem;
          line-height: 1, 2;
          margin-top: 0.25rem;
        } @else {
          font-size: 0.16rem;
          line-height: 0.22rem;
        }
      }
    }

    .icon {
      @if ($searchWidth<1100) {
        width: 4rem;
      } @else {
        width: 1.3rem;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    .s {
      @if ($searchWidth<1100) {
        display: flex;
        // flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 1rem;
      }
    }

    .item-s {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 0.22rem;
      margin-top: 0.12rem;
      background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
      box-shadow: 0 0 0.08rem 0 rgba(83, 177, 165, 0.2);
      border: 0.02rem solid #ffffff;
      text-align: center;

      @if ($searchWidth<1100) {
        font-size: 0.55rem;
        // padding: 0.35rem 0;
        height: 1.5rem;
        line-height: 1.5rem;
        flex-grow: 1;

        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      } @else {
        flex-grow: 1;
        font-size: 0.16rem;
        padding: 0.09rem 0;

        &:not(:last-child) {
          margin-right: 0.1rem;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
